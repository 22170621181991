<template>
    <div class="page-wrapper my-5">
        <main class="main-content">
            <div>
                <div class="mb-4">
                    <div class="d-flex justify-content-center mb-3" v-if="company && company.logoURL">
                        <img :src="company.logoURL" alt="Builders Logo" class="w-full"
                            style="max-width: 100%; max-height: 300px; width: 400px;" />
                    </div>
                    <hr class="m-0" />
                    <form class="container mx-auto" @submit.prevent="saveData">
                        <div class="p-0 pt-5">
                            <div class="p-4 bg-light">
                                <h3 class="mb-4 header-blue-tf-color">Loan Calculator</h3>
                                <div class="row">
                                    <div class="col-12 col-sm-6 col-md-3">
                                        <label class="fw-medium mb-1" for="type">Loan Type</label>
                                        <input class="form-control" readonly value="Personal" />
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3">
                                        <label class="fw-medium mb-1" for="period">Loan Amount</label>
                                        <input id="period" class="form-control" type="number" v-model="amount"
                                            :class="{ 'is-invalid': !periodState }" min="2500" max="20000" required
                                            @focus="handleFocus" @blur="getMonths" />
                                        <div class="invalid-feedback">Min value {{ min }} - Max value {{ max }}</div>
                                    </div>
                                    <div class="col-12 col-sm-6 col-md-3">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="fw-medium mb-1" for="period">Period in Months</label>
                                            <div style="width: 30px;"
                                                class="d-flex justify-content-end align-items-center"
                                                v-if="loadingPeriods">
                                                <!-- <b-spinner small label="Small Spinner"></b-spinner> -->
                                            </div>
                                        </div>
                                        <select class="form-select" v-model="selectedPeriod"
                                            :disabled="periods.length <= 0" @change="getMonthlyPayments" required>
                                            <option v-for="period in periods" :key="period" :value="period">{{ period }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-12 col-sm-6 col-md-3">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="fw-medium mb-1" for="period">Monthly Payments</label>
                                            <div style="width: 30px;"
                                                class="d-flex justify-content-end align-items-center"
                                                v-if="loadingPayment">
                                                <div class="spinner-border spinner-border-sm" role="status"></div>
                                            </div>
                                        </div>
                                        <input class="form-control" v-model="monthly" readonly />
                                    </div>
                                </div>
                            </div>
                            <div class="p-4">
                                <h3 class="mb-3 header-blue-tf-color">Customer Information</h3>
                                <div class="row mb-4">
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="name">First Name</label>
                                        <input class="form-control" v-model="firstName" id="name" type="text"
                                            placeholder="Your name here" required />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="lastName">Last Name</label>
                                        <input class="form-control" v-model="lastName" id="lastName" type="text"
                                            placeholder="Your last name here" required />
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="employer">Employer Name</label>
                                        <input class="form-control" v-model="employerName" id="employer" type="text"
                                            placeholder="Employer name here" required />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="email">Email Address</label>
                                        <input class="form-control" v-model="email" id="email" type="email"
                                            placeholder="Email address here" required />
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="phone">Phone Number</label>
                                        <input class="form-control" v-model="phone" id="phone" type="text"
                                            placeholder="Phone Number here" required />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="address">Address</label>
                                        <input class="form-control" v-model="address" id="address" type="text"
                                            placeholder="Address here" required />
                                    </div>
                                </div>
                            </div>
                            <div class="p-4 bg-light">
                                <h3 class="mb-3 header-blue-tf-color">Customer Documents</h3>
                                <div class="row mb-4">
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="id">Valid ID*</label>
                                        <input class="form-control" type="file" accept=".jpg, .jpeg, .png, .pdf, .docx"
                                            @change="handleFileUpload($event, 'id')" required />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="payslip1">Payslip #1</label>
                                        <input class="form-control" type="file" accept=".jpg, .jpeg, .png, .pdf, .docx"
                                            @change="handleFileUpload($event, 'payslip1')" />
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="payslip2">Payslip #2</label>
                                        <input class="form-control" type="file" accept=".jpg, .jpeg, .png, .pdf, .docx"
                                            @change="handleFileUpload($event, 'payslip2')" />
                                    </div>
                                    <div class="col-sm-6">
                                        <label class="fw-medium mb-1" for="other">Quote (If Applicable)</label>
                                        <input class="form-control" type="file" accept=".jpg, .jpeg, .png, .pdf, .docx"
                                            @change="handleFileUpload($event, 'other')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end pt-2">
                            <button class="btn style1"
                                :disabled="loadingSave || loadingPeriods || loadingPayment || focusAmount"
                                type="submit">
                                <div class="spinner-border spinner-border-sm" role="status" v-if="loadingSave">
                                </div>
                                <span v-else>Send</span>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </main>
    </div>
</template>

<script>
import Navbar from "../Layouts/Navbar";
import Footer from "../Layouts/Footer";
import axios from 'axios'
import Swal from "sweetalert2";


export default {
    name: "HealthSector",
    components: {
        Navbar,
        Footer,
    },
    data: () => ({
        loading: true,
        options: [{ text: "Personal", value: 1 }],
        periods: [],
        selectedType: 1,
        selectedPeriod: null,
        amount: null,
        monthly: null,
        loadingSave: false,
        loadingPeriods: false,
        loadingPayment: false,

        firstName: "",
        lastName: "",
        employerName: "",
        email: "",
        phone: "",
        address: "",
        id: null,
        payslip1: null,
        payslip2: null,
        other: null,
        min: 2500,
        max: 20000,
        company: null,
        focusAmount: false,

        url: "https://qwihi-api-ocs.mindsetgx.app/loans/Api/LoanApplications/",
        urlCompany: "https://qwihi-api-ocs.mindsetgx.app/loans/Api/CompanyLoansApplications/"
    }),

    methods: {
        handleFocus() {
            console.log('test')
            this.focusAmount = true
        },
        async getMonths() {
            this.focusAmount = false
            if (this.periodState && this.amount != null) {
                this.loadingPeriods = true;
                this.periods = await this.get_monthsByLoanType({ amount: this.amount });

                this.selectedPeriod = this.periods[0]
                this.loadingPeriods = false;
                this.getMonthlyPayments();
            }
        },
        async getMonthlyPayments() {
            if (this.periodState && this.selectedPeriod != null) {
                this.loadingPayment = true;
                const res = await this.get_monthlyPayment({ amount: this.amount, months: this.selectedPeriod });
                this.monthly = res.amount;
                this.loadingPayment = false;
            }
        },
        async saveData() {
            this.loadingSave = true;
            let data = {
                amount: this.amount,
                periodInMonths: this.selectedPeriod,
                monthlyPayment: this.monthly,
                firstName: this.firstName,
                lastName: this.lastName,
                employerName: this.employerName,
                email: this.email,
                phone: this.phone,
                address: this.address,
                id: this.id,
                payslip1: this.payslip1,
                quote: this.payslip2,
                other: this.other,
            };
            await this.add_builders(data);
            this.clearValues();
            this.loadingSave = false;
        },
        clearValues() {
            this.firstName = "";
            this.lastName = "";
            this.employerName = "";
            this.email = "";
            this.phone = "";
            this.address = "";
            this.id = null;
            this.payslip1 = null;
            this.payslip2 = null;
            this.other = null;
            this.monthly = null;
            this.amount = null;
            this.selectedPeriod = null;
        },

        handleFileUpload(event, field) {
            this[field] = event.target.files[0];
        },

        async getData() {
            this.loading = true;
            this.company = await this.get_companyInformation();
            const res = await this.get_companyLoanRange();
            this.min = res.minAmount;
            this.max = res.maxAmount;
            this.amount = res.minAmount;
            await this.getMonths()
            this.loading = false;
        },

        async get_companyInformation() {
            try {
                const response = await axios.get(`${this.urlCompany}GetCompanyInformation/${'3'}`);
                return response.data.dataResult;
            } catch (error) {
                console.log(error)
            }
        },

        async get_monthsByLoanType(payload) {
            try {
                const response = await axios.get(
                    `${this.urlCompany}GetMonthsByLoanType?Amount=${payload.amount}&LoanTypeId=1&CompanyId=${'3'}`
                );
                return response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async get_companyLoanRange() {
            try {
                const response = await axios.get(`${this.urlCompany}GetLoanRange?LoanTypeId=1&CompanyId=${'3'}`);
                return response.data;
            } catch (error) {
                console.log(error)

            }
        },

        async get_loanRange(id) {
            try {
                const response = await axios.get(`${this.url}GetLoanRange?LoanTypeId=${id}`);
                return response.data;
            } catch (error) {
                console.log(error)
            }
        },
        async get_monthlyPayment(payload) {
            try {
                const response = await axios.get(
                    `${this.urlCompany}GetMonthtlyPayment?Amount=${payload.amount}&Months=${payload.months}&LoanTypeId=1&CompanyId=${'3'}`
                );
                return response.data;
            } catch (error) {
                console.log(error)
            }
        },

        async add_builders(applicationData) {
            console.log(applicationData);
            try {
                const formData = new FormData();
                formData.append("LoanTypeId", 1);
                formData.append("Amount", applicationData.amount);
                formData.append("MonthPeriod", applicationData.periodInMonths);
                formData.append("MonthPayment", applicationData.monthlyPayment);
                formData.append("FirstName", applicationData.firstName);
                formData.append("LastName", applicationData.lastName);
                formData.append("Email", applicationData.email);
                formData.append("Telephone", applicationData.phone);
                formData.append("Address", applicationData.address);
                formData.append("Employeer", applicationData.employeerName);
                formData.append("DocumentId", applicationData.id);
                formData.append("Payslip", applicationData.payslip1);
                formData.append("Others", applicationData.other);
                formData.append("Quote", applicationData.quote);
                formData.append("CompanyId", '3');

                const response = await axios.post(`${this.urlCompany}Add`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                Swal.fire({
                    icon: "success",
                    title: '<h2 class="swal2-title" id="swal2-title" style="display: block; color: #FFFFFF;">Ok!</h2>',
                    text: "Loan request has been sent to Total.",
                    background: "#0A505D",
                    color: "#FFFFFF",
                    iconColor: "#6BD6D3",
                    confirmButtonColor: "#6BD6D3",
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    allowOutsideClick: false,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.href = "/";
                    }
                });
                return response.data;
            } catch (error) {
                console.log(error)
            }
        },

    },
    computed: {
        periodState() {
            return (this.amount >= this.min && this.amount <= this.max) || this.amount == null;
        },
    },
    created() {
        this.getData();
    },
};
</script>